import React, { Component, useState } from 'react';
import styled from "styled-components";
import { FormContainer, FormFieldRow, FormFieldColumn, FormSection, FormField, FormInputFull, FormInput, FormTextArea, FormLabel, FormSubHeading, FormItalicHeading, FormSectionPadding } from '../../../styles/form-styles.js';
import ABInputField from '../../../components/ABInputField';
import ABButton from '../../../components/ABButton';
import DatePicker from 'react-datepicker';
import colors from '../../../styles/colors';
import ABSelect from '../../../components/ABSelect';

const PageWrapper = styled.div`
  max-width: 1100px;
  margin: 0 auto;
`;

const PageHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

const PageTitle = styled.div`
  color: ${colors.blue};
  font-size: 30px;
  padding: 30px 0 10px 0;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  div:first-child {
    padding-right: 10px;
  }
`;

const RightButton = styled.div`
  display: flex;
  justify-content: space-around;
`;

const DatePickerStyle = styled.div`
  input {
    padding: 10px;
    font-size: 16px;
    width: 100%;
    min-width: 400px;
    margin-bottom: 20px;
    line-height: 25px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledHeading = styled.div`
  padding: 10px 10px 10px 0;
  font-size: 40px;
`;


function StatsImport() {

    const [issue, setIssue] = useState('');
    const [subject, setSubject] = useState('');
    const [date, setDate] = useState<Date | null>(null);
    const [link, setLink] = useState('');
    const [sent, setSent] = useState('');
    const [delivered, setDelivered] = useState('');
    const [bounceRate, setBounceRate] = useState('');
    const [openPercent, setOpenPercent] = useState('');
    const [opens, setOpens] = useState('');
    const [clickPercent, setClickPercent] = useState('');
    const [clicks, setClicks] = useState('');
    const [ctr, setCtr] = useState('');
    const [ctor, setCtor] = useState('');
    const [leads, setLeads] = useState('');

    const [clientId, setCompanyId] = useState('');

    const onCreate = () => {
        // if (issue && title) {
        //     const alertItem: AlertItem = {
        //         issue,
        //         title,
        //         date,
        //         status: 'draft'
        //     }

        //     props.createAlertItem(alertItem);
        // }
    };

    const handleDateChange = (date: Date | null) => {
        date!.setHours(1);
        date!.setMinutes(0);
        date!.setSeconds(0);
        setDate(date);
    };

    const handleCompanyIdChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const clientId = e.currentTarget.value;
        setCompanyId(clientId);
    };

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) =>
        (e: React.ChangeEvent<HTMLInputElement>) => setter(e.currentTarget.value);

    return (
        <PageWrapper>
            <HeadingContainer>
                <StyledHeading>Stats Email Import</StyledHeading>
            </HeadingContainer>

            <FormSubHeading>Issue ID:</FormSubHeading>
            <ABInputField
                value={issue}
                onChange={handleInputChange(setIssue)}
                placeholder="Issue ID"
            />
            <FormSubHeading>Subject:</FormSubHeading>
            <ABInputField
                value={subject}
                onChange={handleInputChange(setSubject)}
                placeholder="Subject"
            />
            <FormSubHeading>Date:</FormSubHeading>
            <DatePickerStyle>
                <DatePicker
                    selected={date}
                    onChange={handleDateChange}
                    dateFormat="MMMM d, yyyy"
                />
            </DatePickerStyle>
            <FormSubHeading>Link:</FormSubHeading>
            <ABInputField
                value={link}
                onChange={handleInputChange(setLink)}
                placeholder="Link"
            />
            <FormSubHeading>Sent:</FormSubHeading>
            <ABInputField
                value={sent}
                onChange={handleInputChange(setSent)}
                placeholder="Sent"
            />
            <FormSubHeading>Delivered:</FormSubHeading>
            <ABInputField
                value={delivered}
                onChange={handleInputChange(setDelivered)}
                placeholder="Delivered"
            />
            <FormSubHeading>Bounce Rate:</FormSubHeading>
            <ABInputField
                value={bounceRate}
                onChange={handleInputChange(setBounceRate)}
                placeholder="Bounce Rate"
            />
            <FormSubHeading>Open Percent:</FormSubHeading>
            <ABInputField
                value={openPercent}
                onChange={handleInputChange(setOpenPercent)}
                placeholder="Open Percent"
            />
            <FormSubHeading>Opens:</FormSubHeading>
            <ABInputField
                value={opens}
                onChange={handleInputChange(setOpens)}
                placeholder="Opens"
            />
            <FormSubHeading>Click Percent:</FormSubHeading>
            <ABInputField
                value={clickPercent}
                onChange={handleInputChange(setClickPercent)}
                placeholder="Click Percent"
            />
            <FormSubHeading>Clicks:</FormSubHeading>
            <ABInputField
                value={clicks}
                onChange={handleInputChange(setClicks)}
                placeholder="Clicks"
            />
            <FormSubHeading>CTR:</FormSubHeading>
            <ABInputField
                value={ctr}
                onChange={handleInputChange(setCtr)}
                placeholder="CTR"
            />
            <FormSubHeading>CTOR:</FormSubHeading>
            <ABInputField
                value={ctor}
                onChange={handleInputChange(setCtor)}
                placeholder="CTOR"
            />
            <FormSubHeading>Leads:</FormSubHeading>
            <ABInputField
                value={leads}
                onChange={handleInputChange(setLeads)}
                placeholder="Leads"
            />

            <ActionButtons>
                <RightButton>
                    <ABButton title="Create Stat" onClick={() => onCreate()} />
                </RightButton>
            </ActionButtons>
        </PageWrapper>
    );
};

export default StatsImport;