import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import { history } from "../../../routers/AppRouter";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimeZoneSelect from '../../../components/TimeZoneSelect';
import Dropzone from 'react-dropzone';
import { extractImageFileExtensionFromBase64 } from '../../../helpers/ReusableUtils';
import { ToastContainer, toast } from 'react-toastify/index';
import 'react-toastify/dist/ReactToastify.css';
import ReactSVG from 'react-svg';
import Modal from 'react-modal';
import '../../../styles/preview-modal.css';
import styled from 'styled-components';
import colors from '../../../styles/colors';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../styles/post-editor.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MainButton from '../../../components/MainButton';

// redux
import { saveSingleFile } from '../../../actions/upload';
import { slugify } from '../../../utility/string-utils';
import { startCreateClient, updateClient } from '../../../actions/clients';

const PageContainer = styled.div`
  padding: 20px 40px;
`;

const MainContentContainer = styled.div`
  display: flex;
`;

const MainContent = styled.div`

`;

const TitleFeaturesSelect = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledIconButtons = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const StyledSidebar = styled.div`
  min-width: 250px;
  padding: 15px;
  margin: 0 0 0 20px;
  background: ${colors.lightestGray};
`;

const SidebarSection = styled.div`
  padding: 15px 0;
`;

const SidebarSectionBackground = styled.div`
  background: ${colors.white};
  padding: 5px;
  max-height: 160px;
  overflow: scroll;
`;

const CheckboxStyled = styled.div`
  padding: 5px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ButtonMargin = styled.div`
  margin-right: 15px;
`;

const StyledHeading = styled.div`
  padding: 10px 0;
  font-size: 40px;
`;

const StyledInput = styled.input`
  padding: 10px;
  font-size: 16px;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
`;

const StyledTextArea = styled.textarea`
  width: 400px;
  font-size: 16px;
  padding: 10px;
`;

const RightSideHeading = styled.div`
  display: flex;
`;

const SubTitle = styled.div`
  padding: 30px 0 20px 0;
  color: ${colors.blue};
  font-size: 22px;
`;

const StyledTitle = styled.div`
  padding: 20px 0 10px 0;
`;

const DisplayTitleCheck = styled.div`
  text-align: right;
  padding: 10px 0 15px 0;
  input {
    margin-right: 7px;
  }
`;

const FeaturedImageSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PreviewSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

const StyledImageSection = styled.div`
  display: flex;
  align-items: center;
`;

const ImagePreview = styled.img`
  max-height: 100px;
  max-width: 150px;
  object-fit: cover;
  margin: 0 15px;
  border: 1px solid ${colors.lightGray};
`;

const ImagePlaceholderStyled = styled.div`
  width: 250px;
  height: 60px;
  background: ${colors.lightestGray};
  color: ${colors.lightGray};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  margin: 15px;
`;

const DisplaySection = styled.div`
  padding-left: 30px;
`;

const FeaturedImageLinkSection = styled.div`
  padding-left: 30px;
  input {
    width: 350px;
  }
`;

const RadioSection = styled.div`
  max-width: 150px;
  label {
    display: block;
    padding: 5px 0;
  }
`;

const LinkInput = styled.input`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const StyledDatePicker = styled(DatePicker)`
  padding: 10px;
  font-size: 16px;
  margin-bottom: 20px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
  width: 400px;
`;

const CategorySection = styled.div`
  margin: 40px 0;
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGray};
`;

const CategorySectionHeading = styled.div`
  font-size: 25px;
  color: ${colors.blue};
`;

const SchedulePopup = styled.div`
  position: absolute;
  padding-top: 10px;
  display: ${props => props.showCalendar ? 'block' : 'none'};
  z-index: 100;
`;

const ImageSelection = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: #1194F6;
  cursor: pointer;
`;

const FileRow = styled.div`
  position: relative;
  button {
    z-index: 1;
    position: absolute;
    bottom: 8px;
    right: 8px;
  }
`;

const MultipleImagePreview = styled.div`
  display: flex;
  padding: 0 10px;
`;

const imageMaxSize = 1000000000; // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif, application/pdf, video/mp4, video/mpg, video/mov, video/wmv, video/avi, video/mpeg4';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => { return item.trim() });

function Client(props) {

  const [id, setId] = useState('');
  const [clientPortalId, setClientPortalId] = useState('');
  const [company, setCompany] = useState('');
  const [assignedUserId, setAssignedUserId] = useState('');
  const [status, setStatus] = useState(true);
  const [notes, setNotes] = useState('');
  const [editing, setEditing] = useState(false);
  const [buttonText, setButtonText] = useState('Creat a Client');

  useEffect(() => {
    // data passed in from the list page
    const data = props.history.location.state?.data;
    if (data) {
      setId(data.id);
      setEditing(true);
      setClientPortalId(data.clientPortalId);
      setCompany(data.company);
      setAssignedUserId(data.assignedUserId);
      setStatus(data.active);
      setNotes(data.notes);
      setButtonText('Update Client');
    }
  }, []);

  const clearState = () => {
    setCompany('');
    setAssignedUserId('');
    setStatus(true);
    setNotes('');
    setClientPortalId('');
    setId('');
    history.push('/client-list');
  };

  const handleClientPortalIdChange = (clientPortalId) => {
    setClientPortalId(clientPortalId);
  };

  const handleCompanyChange = (company) => {
    setCompany(company);
  };

  const handlAassignedUserIdChange = (assignedUserId) => {
    setAssignedUserId(assignedUserId);
  };

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleNotesChange = (notes) => {
    setNotes(notes);
  };

  const createClient = () => {

    if (company) {
      let clientInfo = {
        id,
        company,
        active: status,
        notes
      };

      if (editing) {
        props.updateClient(clientInfo);
      } else {
        props.createClient(clientInfo);
      }

      clearState();
    } else {
      //validation errors
      toast.error('Please fil out all required fields');
    }
  };

  return (
    <PageContainer>
      <HeadingContainer>
        <StyledHeading>{buttonText}</StyledHeading>
        <RightSideHeading>
          <MainButton title={buttonText} dark={true} onClick={createClient} />
        </RightSideHeading>
      </HeadingContainer>
      <MainContentContainer>
        <MainContent>

          {/* <StyledTitle>Client Portal Id</StyledTitle>
                <StyledInput
                    type="text"
                    value={clientPortalId}
                    onChange={(e) => handleClientPortalIdChange(e.target.value)}
                /> */}

          <StyledTitle>Company</StyledTitle>
          <StyledInput
            type="text"
            value={company}
            onChange={(e) => handleCompanyChange(e.target.value)}
          />

          {/* <StyledTitle>Assigned User Id</StyledTitle>
          <StyledInput
            type="text"
            value={assignedUserId}
            onChange={(e) => handlAassignedUserIdChange(e.target.value)}
          /> */}

          <StyledTitle>Status</StyledTitle>
          <RadioSection>
            <label>
              <input
                key="above"
                type="radio"
                value="above"
                checked={status}
                onChange={() => handleStatusChange(true)}
              />
              Active
            </label>
            <label>
              <input
                key="below"
                type="radio"
                value="below"
                checked={!status}
                onChange={() => handleStatusChange(false)}
              />
              Not Active
            </label>
          </RadioSection>

          <StyledTitle>Notes</StyledTitle>
          <StyledTextArea
            name="notes"
            id="notes"
            value={notes}
            onChange={(e) => handleNotesChange(e.target.value)}
          />

        </MainContent>

      </MainContentContainer>
      <ToastContainer />
    </PageContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    // post: state.posts.singlePost,
    // job: state.jobs.singleJob,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createClient: (clientInfo) => dispatch(startCreateClient(clientInfo)),
  updateClient: (clientInfo) => dispatch(updateClient(clientInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);
