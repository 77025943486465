import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute';
import { signOut } from "../actions/sign-in";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import Support from "../pages/Support";
import MobilePrivacy from "../pages/privacy/MobilePrivacy";
import WebPrivacy from "../pages/privacy/WebPrivacy";
import ResetPassword from "../pages/ResetPassword";
import Xchange from "../pages/Xchange";
import Home from "../Home";

import { createBrowserHistory } from 'history';
import MainLayout from "../MainLayout";
import FolderDetails from "../pages/FolderDetails";
import MicrositeSubmission from "../pages/MicrositeSubmission";
import MicrositeUploads from "../pages/MicrositeUploads";
import Admin from "../pages/admin/Admin";
import Posts from '../pages/admin/posts/Posts';
import Post from '../pages/admin/posts/Post';
import Microsites from '../pages/admin/microsites/Microsites';
import Microsite from '../pages/admin/microsites/Microsite';
import ContactForms from '../pages/admin/forms/ContactForms';
import CareerCenter from '../pages/admin/career-center/CareerCenter';
import CareerListing from '../pages/admin/career-center/CareerListing';
import Events from '../pages/admin/events/Events';
import EventListing from '../pages/admin/events/EventListing';
import AdManager from '../pages/admin/ad-manager/AdManager';
import Ad from '../pages/admin/ad-manager/Ad';
import MediaLibrary from '../pages/admin/media-library/MediaLibrary';
import MediaImage from '../pages/admin/media-library/MediaImage';
import MediaLibraryImageView from '../pages/admin/media-library/MediaLibraryImageView';
import MultipleUpload from '../pages/admin/media-library/MultipleUpload';
import MediaLibraryMultiple from '../pages/admin/media-library/MediaLibraryPagination';
import UserList from '../pages/admin/users/UserList';
import User from '../pages/admin/users/User';
import ClientList from '../pages/admin/clients/ClientList';
import Client from '../pages/admin/clients/Client';
import OrganizationDetails from '../pages/admin/microsites/OrganizationDetails';
import CallToAction from '../pages/admin/microsites/CallToAction';
import LogoBio from '../pages/admin/microsites/LogoBio';
import Keywords from '../pages/admin/microsites/Keywords';
import Focus from '../pages/admin/microsites/Focus';
import Panels from '../pages/admin/microsites/Panels';
import Resources from '../pages/admin/microsites/Resources';
import BeConnected from '../pages/admin/microsites/BeConnected';
import MediaGallery from '../pages/admin/microsites/MediaGallery';
import UserAccess from '../pages/admin/microsites/UserAccess';
import CreateMicrosite from '../pages/admin/microsites/CreateMicrosite';
import AspenAlert from "../pages/admin/aspen-alert/AspenAlert";
import AnnexPages from "../pages/admin/annex/AnnexPages";
import EditAnnexPage from "../pages/admin/annex/EditAnnexPage";
import StatsExport from "../pages/admin/stats/StatsExport";
import ClientPortalAccess from '../pages/admin/microsites/ClientPortalAccess';
import Alerts from '../pages/admin/alerts/Alerts';
import Alert from '../pages/admin/alerts/Alert';
import CreateAlert from '../pages/admin/alerts/CreateAlert';
import AlertEmailPreview from '../pages/admin/alerts/alert-email-preview/AlertEmailPreview';
import AlertHolidayEmailPreview from '../pages/admin/alerts/alert-holiday-email-preview/AlertHolidayEmailPreview';
import StatsImport from '../pages/admin/stats/StatsImport';

export const history = createBrowserHistory();

class AppRouter extends Component {

  constructor(props) {
    super(props)
  }

  onSignout = () => {
    this.props.signOut()
  };

  render() {

    return (
      <Router history={history}>
        <MainLayout isAuthed={this.props.isAuthenticated}>
          {
            this.props.isAuthenticated !== null &&
            <Switch>
              <Route path="/" component={SignIn} exact={true} />
              <Route path="/aspen-alert" component={AspenAlert} />
              <Route path="/xchange" component={Xchange} />
              <Route path="/microsite-submission" component={MicrositeSubmission} />
              <Route path="/sign-up" component={SignUp} />
              <Route path="/sign-in" component={SignIn} />
              <Route path="/reset/:token" component={ResetPassword} />
              <Route path="/support" component={Support} />
              <Route path="/privacy" component={WebPrivacy} />
              <Route path="/mobile-privacy" component={MobilePrivacy} />
              <PrivateRoute path="/home" component={Home} />
              <PrivateRoute path="/microsite-uploads" component={MicrositeUploads} exact={true} />
              <PrivateRoute path="/microsite-uploads/:folder" component={FolderDetails} />
              <PrivateRoute path="/admin" component={Admin} />
              <PrivateRoute path="/alerts" component={Alerts} />
              <PrivateRoute path="/alert/:id" component={Alert} />
              <PrivateRoute path="/alert-email-preview/:id" component={AlertEmailPreview} />
              <PrivateRoute path="/alert-holiday-email-preview/:id" component={AlertHolidayEmailPreview} />
              {/* <PrivateRoute path="/alert-email-preview" component={AlertEmailPreview} /> */}
              <PrivateRoute path="/create-alert" component={CreateAlert} />
              <PrivateRoute path="/posts" component={Posts} />
              <PrivateRoute path="/post/:id" component={Post} />
              <PrivateRoute path="/post" component={Post} />
              <PrivateRoute path="/contact-forms" component={ContactForms} />
              <PrivateRoute path="/career-center" component={CareerCenter} />
              <PrivateRoute path="/career-listing/:id" component={CareerListing} />
              <PrivateRoute path="/career-listing" component={CareerListing} />
              <PrivateRoute path="/events" component={Events} />
              <PrivateRoute path="/event-listing/:id" component={EventListing} />
              <PrivateRoute path="/event-listing" component={EventListing} />
              <PrivateRoute path="/ad-manager" component={AdManager} />
              <PrivateRoute path="/ad/:id" component={Ad} />
              <PrivateRoute path="/ad" component={Ad} />
              <PrivateRoute path="/media-library" component={MediaLibrary} exact={true} />
              <PrivateRoute path="/media-library-multiple" component={MediaLibraryMultiple} exact={true} />
              <PrivateRoute path="/media-library-image-view" component={MediaLibraryImageView} />
              <PrivateRoute path="/media-library/multiple-upload" component={MultipleUpload} />
              <PrivateRoute path="/media-image/:id" component={MediaImage} />
              <PrivateRoute path="/media-image" component={MediaImage} />
              <PrivateRoute path="/user-list" component={UserList} />
              <PrivateRoute path="/user" component={User} />
              <PrivateRoute path="/client-list" component={ClientList} />
              <PrivateRoute path="/client" component={Client} />
              <PrivateRoute path="/aspen-alert" component={AspenAlert} />
              <PrivateRoute path="/annex" component={AnnexPages} exact={true} />
              <PrivateRoute path="/annex/:title" component={EditAnnexPage} />
              <PrivateRoute path="/stats-export" component={StatsExport} />
              <PrivateRoute path="/stats-import" component={StatsImport} />

              <PrivateRoute path="/microsites" component={Microsites} exact={true} />
              <PrivateRoute path="/microsite/create-microsite/" component={CreateMicrosite} exact={true} />
              <PrivateRoute path="/microsite/:id" component={Microsite} exact={true} />
              <PrivateRoute path="/microsite/organization-details/:id" component={OrganizationDetails} />
              <PrivateRoute path="/microsite/call-to-action/:id" component={CallToAction} />
              <PrivateRoute path="/microsite/logo-bio/:id" component={LogoBio} />
              <PrivateRoute path="/microsite/keywords/:id" component={Keywords} />
              <PrivateRoute path="/microsite/focus/:id" component={Focus} />
              <PrivateRoute path="/microsite/panels/:id" component={Panels} />
              <PrivateRoute path="/microsite/resources/:id" component={Resources} />
              <PrivateRoute path="/microsite/be-connected/:id" component={BeConnected} />
              <PrivateRoute path="/microsite/media-gallery/:id" component={MediaGallery} />
              <PrivateRoute path="/microsite/user-access/:id" component={UserAccess} />
              <PrivateRoute path="/microsite/client-portal-access/:id" component={ClientPortalAccess} />
              {/*<PrivateRoute path="/account-info" component={AccountInfo} />*/}
              {/*<PrivateRoute path="/resume" component={Resume} />*/}
              {/*<PrivateRoute path="/password" component={Password} />*/}
              {/*<PrivateRoute path="/reviews" component={Reviews} />*/}
              {/*<PrivateRoute path="/sent-requests" component={ReviewRequests} />*/}
            </Switch>
          }
          {/*{*/}
          {/*<Switch>*/}
          {/*<Route path="/" component={SignIn} exact={true} />*/}
          {/*<Route path="/aspen-alert" component={AspenAlert} />*/}
          {/*<Route path="/xchange" component={Xchange} />*/}
          {/*<Route path="/microsite-submission" component={MicrositeSubmission} />*/}
          {/*<Route path="/sign-up" component={SignUp} />*/}
          {/*<Route path="/sign-in" component={SignIn} />*/}
          {/*<Route path="/reset/:token" component={ResetPassword} />*/}
          {/*<Route path="/support" component={Support} />*/}
          {/*<Route path="/privacy" component={WebPrivacy} />*/}
          {/*<Route path="/mobile-privacy" component={MobilePrivacy} />*/}
          {/*</Switch>*/}
          {/*}*/}
        </MainLayout>
      </Router>
    )

  }

}

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.userInfo.token,
  profile: state.userInfo.profile
});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
